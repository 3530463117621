var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-view-list',{attrs:{"loading":_vm.data.loading},scopedSlots:_vm._u([{key:"breadcrumb",fn:function(){return [_c('app-view-breadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs,"data":_vm.data}})]},proxy:true},{key:"communicate",fn:function(){return [(_vm.communicate)?_c('b-field',{staticClass:"has-text-centered",class:_vm.communicateClasses},[_vm._v(_vm._s(_vm.communicate))]):_vm._e()]},proxy:true},{key:"actions",fn:function(){return _vm._l((_vm.allowedActions),function(action,id){return _c('b-button',{key:id,attrs:{"type":action.type,"tag":"router-link","to":_vm.getActionRoute(action)}},[_vm._v(_vm._s(action.label))])})},proxy:true}])},[_c('app-table',{attrs:{"url":_vm.getUrl(),"resource":_vm.resource,"sortable":_vm.sortable,"countable":_vm.countable,"properties":_vm.properties,"hide-filters":_vm.hideFilters,"row-actions":_vm.rowActions},scopedSlots:_vm._u([{key:"filters",fn:function(props){return [_c('div',{staticClass:"columns"},[_vm._l((_vm.filters),function(ref){
var filter = ref.filter;
var label = ref.label;
var field = ref.field;
var filterOptions = ref.filterOptions;
return [(filter === 'text')?_c('div',{staticClass:"column"},[_c('app-table-filter-text',_vm._b({attrs:{"label":label,"field":field}},'app-table-filter-text',props,false))],1):_vm._e(),(filter === 'boolean')?_c('div',{staticClass:"column"},[_c('app-table-filter-boolean',_vm._b({attrs:{"label":label,"field":field}},'app-table-filter-boolean',props,false))],1):_vm._e(),(filter === 'select')?_c('div',{staticClass:"column"},[_c('app-table-filter-select',_vm._b({attrs:{"label":label,"field":field}},'app-table-filter-select',props,false),_vm._l((filterOptions),function(ref){
var label = ref.label;
var value = ref.value;
return _c('option',{domProps:{"value":value}},[_vm._v(_vm._s(label))])}),0)],1):_vm._e()]})],2)]}},{key:"headers",fn:function(props){return _vm._l((_vm.headers),function(ref,id){
var label = ref.label;
var sortable = ref.sortable;
return _c('app-table-head',_vm._b({key:id,attrs:{"label":label,"sortable":sortable}},'app-table-head',props,false))})}},{key:"default",fn:function(ref){
var row = ref.row;
var perPage = ref.perPage;
var total = ref.total;
return [_vm._l((_vm.headers),function(header){return [(header.type === 'virtual')?_c('td',[_vm._v(_vm._s(header.value(header, row)))]):_vm._e(),(header.type === 'text')?_c('td',[_vm._v(_vm._s(_vm.getValue(header, row)))]):_vm._e(),(header.type === 'boolean')?_c('td',[_c('app-button-toggle',{attrs:{"resource":_vm.resource,"object":row,"field":header.field}})],1):_vm._e(),(header.type === 'image')?_c('td',[(_vm.getImage(header.field, row))?_c('figure',{staticClass:"image is-4by3"},[_c('img',{attrs:{"src":_vm.getImage(header.field, row)}})]):[_vm._v("Brak")]],2):_vm._e(),(header.type === 'link')?_c('td',[_c('router-link',{attrs:{"to":header.link.route({row: row})}},[_vm._v(_vm._s(header.link.label))])],1):_vm._e(),(header.type === 'links')?_c('td',[_vm._l((header.links),function(link){return [_c('router-link',{attrs:{"to":link.route({row: row})}},[_vm._v(_vm._s(link.label))]),(header.links.indexOf(link) !== header.links.length - 1)?[_vm._v("|")]:_vm._e()]})],2):_vm._e(),(header.type === 'buttons')?_c('td',[_c('div',{staticClass:"buttons has-addons"},[_vm._l((header.buttons),function(button){return [(button.isLink)?_c('a',{staticClass:"button is-small",on:{"click":function($event){$event.preventDefault();return button.download({row: row})}}},[_vm._v(_vm._s(button.label))]):(!button.boolStatement)?_c('router-link',{staticClass:"button is-small",attrs:{"to":button.route({row: row})}},[_vm._v(_vm._s(button.label))]):(button.boolStatement({row: row}))?_c('router-link',{staticClass:"button is-small",attrs:{"to":button.route({row: row})}},[_vm._v(_vm._s(button.label))]):_vm._e()]})],2)]):_vm._e()]})]}},(_vm.rowActions && _vm.rowActions.length)?{key:"actions",fn:function(ref){
var row = ref.row;
var reload = ref.reload;
return [_vm._l((_vm.rowActions),function(action){return [(_vm.canEdit(action))?_c('app-button-edit',{attrs:{"to":_vm.updateRoute(row),"icon":_vm.getUpdateIcon(action)}}):_vm._e(),(_vm.canRemove(action))?_c('app-button-remove',{attrs:{"resource":_vm.resource,"object":row,"size":"is-small","error-codes":_vm.deleteErrors},on:{"removed":function($event){return reload(_vm.actionAfterDelete, $event)}}}):_vm._e()]})]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }