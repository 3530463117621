<template lang="pug">
    app-view-create(@to-list="toList" :loading="data.loading")
        template(#breadcrumb)
            app-view-breadcrumbs(v-if="!data.loading" :breadcrumbs="breadcrumbs" :data="data")
        component(:is="formComponent" @success="toUpdate" :toList="toList")
</template>
<script>
export default {
  props: {
    breadcrumbs: Array,
    formComponent: Object,
    listRoute: Function,
    updateRoute: Function,
    onCreated: Function,
    redirectToListAfterCreate: { type: Boolean, default: false }
  },
  data() {
    return {
      data: {
        loading: false
      }
    };
  },
  methods: {
    toList() {
      this.$router.push(
        this.listRoute({
          compData: this.data,
          router: this.$router,
          route: this.$route
        })
      );
    },
    toUpdate(data) {
      if (!this.redirectToListAfterCreate) {
        this.$router.push(
          this.updateRoute({
            compData: this.data,
            object: data,
            router: this.$router,
            route: this.$route
          })
        );
      } else {
        this.toList();
      }
    }
  },
  created() {
    if (this.onCreated) {
      this.onCreated({
        compData: this.data,
        route: this.$route,
        $notify: this.$notify,
        $http: this.$http
      });
    }
  }
};
</script>
