<template lang="pug">
    app-view-list(:loading="data.loading")
        template(#breadcrumb)
            app-view-breadcrumbs(:breadcrumbs="breadcrumbs" :data="data")

        template(#communicate)
            b-field.has-text-centered(v-if="communicate" :class="communicateClasses") {{ communicate }}
        template(#actions)
            b-button(
                v-for="(action,id) in allowedActions"
                :type="action.type"
                tag="router-link"
                :to="getActionRoute(action)"
                :key="id"
            ) {{ action.label }}

        app-table(
            :url="getUrl()"
            :resource="resource"
            :sortable="sortable"
            :countable="countable"
            :properties="properties"
            :hide-filters="hideFilters"
            :row-actions="rowActions"

        )
            template(#filters="props")
                .columns
                    template(v-for="{filter, label, field, filterOptions} in filters")
                        .column(v-if="filter === 'text'")
                            app-table-filter-text(
                                :label="label"
                                :field="field"
                                v-bind="props"
                            )
                        .column(v-if="filter === 'boolean'")
                            app-table-filter-boolean(
                                :label="label"
                                :field="field"
                                v-bind="props"
                            )
                        .column(v-if="filter === 'select'")
                            app-table-filter-select(
                                :label="label"
                                :field="field"
                                v-bind="props"
                            )
                                option(v-for="{label, value} in filterOptions" :value="value") {{ label }}

            template(#headers="props")
                app-table-head(
                    v-for="({label, sortable}, id) in headers"
                    v-bind="props"
                    :label="label"
                    :sortable="sortable"
                    :key="id"
                )

            template(#default="{ row, perPage, total }")
                template(v-for="header in headers")
                    td(v-if="header.type === 'virtual'") {{ header.value(header, row) }}

                    td(v-if="header.type === 'text'") {{ getValue(header, row) }}

                    td(v-if="header.type === 'boolean'")
                        app-button-toggle(
                            :resource="resource"
                            :object="row"
                            :field="header.field"
                        )

                    td(v-if="header.type === 'image'")
                        figure(v-if="getImage(header.field, row)" class="image is-4by3")
                            img(:src="getImage(header.field, row)")
                        template(v-else) Brak

                    td(v-if="header.type === 'link'")
                        router-link(:to="header.link.route({row})") {{ header.link.label }}

                    td(v-if="header.type === 'links'")
                        template(v-for="link in header.links")
                            router-link(:to="link.route({row})") {{ link.label }}
                            template(v-if="header.links.indexOf(link) !== header.links.length - 1") |

                    td(v-if="header.type === 'buttons'")
                        .buttons.has-addons
                            template(v-for="button in header.buttons")
                                a(v-if="button.isLink" class="button is-small"  @click.prevent="button.download({row})") {{ button.label }}
                                router-link(v-else-if="!button.boolStatement" :to="button.route({row})" class="button is-small") {{ button.label }}
                                router-link(v-else-if="button.boolStatement({row})" :to="button.route({row})" class="button is-small") {{ button.label }}

            template(#actions="{ row, reload }" v-if="rowActions && rowActions.length")
                template(v-for="action in rowActions")
                    app-button-edit(v-if="canEdit(action)" :to="updateRoute(row)" :icon="getUpdateIcon(action)")
                    app-button-remove(v-if="canRemove(action)" :resource="resource" :object="row" size="is-small" :error-codes="deleteErrors" @removed="reload(actionAfterDelete, $event)")
</template>
<script>
export default {
  props: {
    breadcrumbs: Array,
    sortable: Boolean,
    countable: Boolean,
    fields: Array,
    hideFilters: Boolean,
    actions: {
      type: Array,
      default: () => []
    },
    url: Function,
    resource: String,
    onCreated: Function,
    actionAfterDelete: Function,
    rowActions: Array,
    properties: Array,
    communicate: { type: String, default: null },
    communicateType: { type: String, default: "warning" },
    deleteErrors: Array
  },
  data() {
    return {
      data: {
        loading: false
      }
    };
  },
  computed: {
    allowedActions() {
      return this.actions;
    },
    filters() {
      const filters = this.fields.filter(field => field.filter);
      return filters.map(field => {
        if (field.filterOptions && typeof field.filterOptions === "function") {
          field.filterOptions = field.filterOptions();
        }
        return field;
      });
    },
    headers() {
      return this.fields;
    },
    communicateClasses() {
      switch (this.communicateType) {
        case "warning":
          return {
            "has-text-danger": true,
            "has-font-size-medium": true
          };
        case "info":
          return {
            "has-text-info": true,
            "has-font-size-small": true
          };
        default:
          return {
            "has-text-danger": true,
            "has-font-size-medium": true
          };
      }
    }
  },
  methods: {
    canEdit(action) {
      return action.action === "edit";
    },
    canRemove(action) {
      return action.action === "remove";
    },
    getUpdateIcon(action) {
      return action.icon || "pencil";
    },
    getImage(field, row) {
      const value = this.getValue({ field }, row);
      if (value) {
        return value.contentUrl;
      }
      return null;
    },
    getValue(header, obj) {
      const value = header.field
        .split(".")
        .reduce(
          (o, i) => (typeof o == "undefined" || o === null ? o : o[i]),
          obj
        );
      if (header.format) {
        return header.format(value);
      }
      return value;
    },
    getActionRoute(action) {
      return action.route({ route: this.$route });
    },
    getUrl() {
      return this.url({ route: this.$route, compData: this.$data });
    },
    updateRoute(row) {
      return {
        name: `${this.resource}_update`,
        params: { id: row.id }
      };
    }
  },
  created() {
    if (this.onCreated) {
      this.onCreated({
        compData: this.data,
        route: this.$route,
        $notify: this.$notify,
        $http: this.$http
      });
    }
  }
};
</script>
